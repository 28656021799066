import Modal from "antd/lib/modal/Modal";
import { Button, Row, Col, Tabs, Descriptions } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { toGetFactureReception, toPrintFacture } from "../APIs";
import { useEffect, useState } from "react";
import Loading from "react-loading-bar";
const { TabPane } = Tabs;

const FactureReceptionModal = ({ factureReception, onClose }) => {
  const [facture, setFacture] = useState(null);
  const [show, setShow] = useState(true);
  const [paiementActive, setPaiementActive] = useState(false);

  useEffect(() => {
    setFacture(null);

    if (null != factureReception) {
      toGetFactureReception(factureReception.id).then((res) => {
        setFacture(res);
        setShow(false);
      });
    }
  }, [factureReception]);
  const printFacture = () => {
    toPrintFacture(facture.id);
  };

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
        break;
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 6:
        return "Contre remboursement";

      default:
        break;
    }
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={factureReception != null}
      onCancel={onClose}
      title="Details de la facture"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
        <Button
          key="submit"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={printFacture}
        >
          Imprimer
        </Button>,
      ]}
    >
      <Loading show={show} color="red" showSpinner={true} />

      {facture && (
        <>
          <h1 style={{ textAlign: "center" }}>Facture Réception</h1>
          <Row style={{ marginBottom: "10px" }}>
            <Col className="info-box" md={11}>
              <p>
                <b>Ref</b> : {facture.s__ref}
              </p>
              <p>
                <b>Date</b> : {toConvertDate(facture.dt__createdDate)}
              </p>
              <p>
                <b>Chargé fournisseur</b> :{" "}
                {facture.l__createdBy && facture.l__createdBy.nom}
              </p>
            </Col>

            <Col className="info-box" md={12}>
              <h1>Fournisseur</h1>
              <p>
                <b>Nom</b> : {facture.provider && facture.provider.s__nom}
              </p>
              <p>
                <b>Tel</b> :{" "}
                {/*facture.provider && facture.provider.s__phoneNumber*/}
              </p>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1">
            <TabPane tab="Détail" key="1">
              {facture.bons__reception.map((br) => (
                <>
                  <h4>
                    {" "}
                    BL fournisseur: {br.s__blProvider}
                    <span style={{ float: "right", marginRight: "20px" }}>
                      Bon de réception : {br.s__ref}
                    </span>
                  </h4>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell" style={{ width: "8%" }}>
                          Ref
                        </th>
                        <th className="ant-table-cell">Désignation</th>
                        <th className="ant-table-cell" style={{ width: "8%" }}>
                          Prix Unitaire HT
                        </th>
                        <th className="ant-table-cell" style={{ width: "8%" }}>
                          Prix Unitaire TTC
                        </th>
                        <th className="ant-table-cell" style={{ width: "8%" }}>
                          Quantité
                        </th>
                        <th className="ant-table-cell" style={{ width: "8%" }}>
                          Remise
                        </th>
                        <th className="ant-table-cell" style={{ width: "8%" }}>
                          Prix Total
                        </th>
                        <th className="ant-table-cell" style={{ width: "5%" }}>
                          TVA
                        </th>
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody">
                      {br.items &&
                        br.items.map((item) => (
                          <tr>
                            <td
                              className="ant-table-cell"
                              style={{ width: "8%" }}
                            >
                              {item.article.s__reference}
                            </td>
                            <td className="ant-table-cell">
                              {item.article.s__firstTitle}
                            </td>
                            <td
                              className="ant-table-cell"
                              style={{ width: "12%" }}
                            >
                              {item.d__unitPriceHT.toFixed(3)}
                            </td>
                            <td
                              className="ant-table-cell"
                              style={{ width: "12%" }}
                            >
                              {item.d__unitPriceTTC.toFixed(3)}
                            </td>
                            <td
                              className="ant-table-cell"
                              style={{ width: "10%" }}
                            >
                              {item.d__quantity}
                            </td>
                            <td
                              className="ant-table-cell"
                              style={{ width: "10%" }}
                            >
                              {parseFloat(item.d__discount).toFixed(2)} %
                            </td>
                            <td
                              className="ant-table-cell"
                              style={{ width: "12%" }}
                            >
                              {item.d__total.toFixed(2)} Dt
                            </td>
                            <td
                              className="ant-table-cell"
                              style={{ width: "6%" }}
                            >
                              {item.d__tva} %
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              ))}
            </TabPane>
            <TabPane tab="Paiement" key="2">
              {facture.reglements.map((reglement) => (
                <Descriptions
                  column={6}
                  bordered
                  size="small"
                  style={{ marginTop: "2vh" }}
                >
                  {reglement.type == 1 || reglement.type == 2 ? (
                    <>
                      <Descriptions.Item label="Type" className="item-label">
                        {getReglementType(reglement)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Num" className="item-label">
                        {reglement.data.num}
                      </Descriptions.Item>
                      <Descriptions.Item label="Nom" className="item-label">
                        {reglement.data.nom}
                      </Descriptions.Item>
                      <Descriptions.Item label="Date" className="item-label">
                        {toConvertDate(reglement.data.date)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Banque" className="item-label">
                        {reglement.data.banque}
                      </Descriptions.Item>
                      <Descriptions.Item label="Montant" className="item-label">
                        {reglement.data.montant}
                      </Descriptions.Item>
                    </>
                  ) : (
                    <Descriptions.Item label="Montant" className="item-label">
                      {reglement.data.montant}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              ))}
            </TabPane>
          </Tabs>

          <div style={{ textAlign: "right", marginTop: 20 }}>
            <div
              style={{
                display: "inline-block",
                textAlign: "right",
              }}
            >
              <p>
                <b>Tota HT</b> : {facture.d__totalHT.toFixed(3)} DT
              </p>
              <p>
                <b>TVA</b> :{" "}
                {((facture.d__tva / facture.d__totalHT) * 100).toFixed(2)} %
              </p>
              <p>
                <b>Remise Totale</b> :{" "}
                {parseFloat(facture.d__discount).toFixed(2)} %
              </p>
              <p>
                <b>Total TTC</b> : {parseFloat(facture.d__total).toFixed(3)} DT
              </p>
              <p>
                <b>Timbre Fiscal</b> : 1.000 DT
              </p>
              <p>
                <b>Net à Payer</b> : {facture.d__totalFacture.toFixed(3)} DT
              </p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default FactureReceptionModal;
