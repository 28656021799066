import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useState } from "react";
import { printStock as getArticles } from "./Api";
import moment from "moment";
import printJS from "print-js";
import DataTable from "react-data-table-component";
import { numberFormatCurrency } from "../../Utils/Utils";
import ArticleHistory from "./ArticleHistory";
import {
  PlusOutlined,
  FileTextOutlined,
  PrinterOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import ArticleDetail from "./ArticleDetail";
const Option = { Select };
const StockZero = ({ isOpen, onClose }) => {
  const [nb, setNb] = useState(0);
  const [index, setIndex] = useState(0);
  const [date, setDate] = useState("");
  const [articles, setArticles] = useState([]);
  const [articleHistory, setArticleHistory] = useState(null);
  const [detailVisble, setDetailVisible] = useState(false);
  const [articleDisplay, setArticleDisplay] = useState("");

  const getArticlesList = async () => {
    await getArticles({ nb, index, date }).then((res) => {
      setArticles(res);
    });
  };
  const toDisplayArticle = (article) => {
    setArticleDisplay({
      id: article.id,
      titre: article.s__firstTitle,
      titre2: article.s__secondTitle,
      reference: article.s__reference,
      code: article.s__barCode,
      type: article.s__type,
      unite: article.unite,
      marque: article.marqueTitle,
      categorie: article.categoryTitle,
      emplacement: null != article.emplacement ? article.emplacement.title : "",
      seuil: article.l__seuil,
      tva: article.tva,
      prixAchatHT: article.l__prixAchatHT,
      prixAchatTTC: article.l__prixAchatTTC,
      data: article.data,
      b__tecdoc: article.b__tecdoc,
      marge: article.l__marge,
      margeValue: article.l__margeValue,
      prixVenteHT: article.l__prixVenteHT,
      prixVenteTTC: article.l__prixVenteTTC,
      minimumQte: article.minimumQte,
      margeGros: article.l__margeGros,
      margeGrosValue: article.l__margeGrosValue,
      prixVenteGrosHT: article.l__prixVenteGrosHT,
      prixVenteGrosTTC: article.l__prixVenteGrosTTC,
    });
    setDetailVisible(true);
  };
  const toPrint = () => {
    printJS({
      printable: articles,
      style: "body { margin: 0; font-size: 14px; }",
      properties: [
        "référence",
        "article",
        "emplacement",
        "vente",
        "achat",
        "prixTTC",
        "quantité",
      ],
      type: "json",
      header: `<h3>Etat stock ${moment(date[0]).format(
        "YYYY-MM-DD"
      )} -> ${moment(date[1]).format("YYYY-MM-DD")}</h3>`,
    });
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };
  const columns = [
    {
      name: "Titre",
      selector: "article",
      grow: 2.5,
    },
    {
      name: "Référence",
      selector: "référence",
      grow: 2.5,
    },

    {
      name: "Date dernière vente",
      selector: "vente",
    },
    {
      name: "Date dernier achat",
      selector: "achat",
    },

    {
      name: "Prix de vente HT",
      selector: "displayableName",
      sortable: true,
      format: (article) => `${numberFormatCurrency(article.prixHT)} `,
    },
    {
      name: "Prix de vente TTC",
      selector: "displayableName",
      sortable: true,
      format: (article) => `${numberFormatCurrency(article.prixTTC)} `,
    },
    {
      name: "Stock",
      selector: "quantité",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "stock",
      grow: 1.5,
      format: (article) => (
        <>
          <Button
            type="link"
            size="large"
            icon={<RiseOutlined />}
            onClick={(e) =>
              setArticleHistory({
                id: article.id,
                titre: article.article,
                titre2: article.article,
                reference: article.référence,
                code: article.s__barCode,
                type: article.s__type,
                unite: article.unite,
                marque: article.marqueTitle,
                categorie: article.categoryTitle,
                emplacement:
                  null != article.emplacement ? article.emplacement.title : "",
                seuil: article.l__seuil,
                tva: article.tva,
                prixAchatHT: article.l__prixAchatHT,
                prixAchatTTC: article.l__prixAchatTTC,
                data: article.data,
                b__tecdoc: article.b__tecdoc,
                marge: article.l__marge,
                margeValue: article.l__margeValue,
                prixVenteHT: article.l__prixVenteHT,
                prixVenteTTC: article.l__prixVenteTTC,

                margeGros: article.l__margeGros,
                margeGrosValue: article.l__margeGrosValue,
                prixVenteGrosHT: article.l__prixVenteGrosHT,
                prixVenteGrosTTC: article.l__prixVenteGrosTTC,
              })
            }
          ></Button>
        </>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) =>
        moment(new Date()).diff(
          moment(row.dtVente != "-" ? row.dtVente : "2024-01-01"),
          "days"
        ) > 30,
      style: {
        backgroundColor: "#fff2cc",
      },
    },
    {
      when: (row) =>
        moment(new Date()).diff(
          moment(row.dtVente != "-" ? row.dtVente : "2024-01-01"),
          "days"
        ) > 90,
      style: {
        backgroundColor: "#f9cb9c",
        color: "white",
      },
    },
    {
      when: (row) =>
        moment(new Date()).diff(
          moment(row.dtVente != "-" ? row.dtVente : "2024-01-01"),
          "days"
        ) > 180,
      style: {
        backgroundColor: "#ff7b7b",
        color: "white",
      },
    },
  ];

  return (
    <Modal
      title="Stock 0"
      visible={isOpen}
      footer={null}
      width={1200}
      onCancel={() => onClose()}
    >
      <Form>
        <Row gutter="6" className="auto--custom-header">
          <Col span="8">
            <Form.Item label="Stock" name="stock">
              <Select defaultValue="Tout" onChange={(e) => setIndex(e)}>
                <Option value="0">Tout</Option>

                <Option value="1">Egal</Option>

                <Option value="2">Supérieur</Option>
                <Option value="3">Inférieur</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="7">
            <Form.Item label name="stockValue">
              <InputNumber onChange={(v) => setNb(v)} />
            </Form.Item>
          </Col>
          <Col span="8">
            <Form.Item label="Date" name="date">
              <DatePicker.RangePicker
                //defaultValue={dayjs(Date(), "YYYY-MM-DD")}
                //format={"YYYY-MM-DD"}
                onChange={(e, date) => setDate(date)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button
          onClick={() => getArticlesList()}
          disabled={date === ""}
          type="primary"
        >
          Rechercher
        </Button>

        <Button
          onClick={() => toPrint()}
          disabled={articles.length === 0}
          style={{ marginLeft: "3rem" }}
          type="primary"
        >
          Imprimer
        </Button>
      </Form>
      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={articles}
        pagination
        noHeader
        striped
        selectableRowsHighlight
        fixedHeader
        persistTableHead
        noDataComponent={<span>Pas d'articles trouvés</span>}
        contextMessage={{
          singular: "élément",
          plural: "éléments",
          message: "séléctionné(s)",
        }}
        conditionalRowStyles={conditionalRowStyles}
      />
      {null != articleHistory && (
        <ArticleHistory
          setArticleHistory={() => setArticleHistory(null)}
          article={articleHistory}
          visible={articleHistory != null}
        />
      )}
    </Modal>
  );
};
export default StockZero;
