// AUTH ACTIONS

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const LOAD_USER = "LOAD_USER";

const AUTH_ACTIONS = {
  LOGIN,
  LOGOUT,
  LOAD_USER,
};

// UI Actions

const SET_LAYOUT = "SET_LAYOUT";
const SET_CONFIG = "SET_CONFIG";
const LOAD_DEFAULT = "LOAD_DEFAULT";

const UI_ACTIONS = {
  SET_LAYOUT,
  SET_CONFIG,
  LOAD_DEFAULT,
};

//Mode
const SET_MODE = "SET_MODE";
const MODE_ACTIONS = {
  SET_MODE,
};

// Quotes Actions

const SET_QUOTE_TO_UPDATE = "SET_QUOTE_TO_UPDATE";

// Items Actions
const CLEAR_ITEMS = "CLEAR_ITEMS";
const SET_TOTAL = "SET_TOTAL";
const SET_TOTAL_DISCOUNT = "SET_TOTAL_DISCOUNT";
const SET_UNIT_PRICE_TTC = "SET_UNIT_PRICE_TTC";
const SET_UNIT_PRICE_HT = "SET_UNIT_PRICE_HT";
const SET_TITLE = "SET_TITLE";
const ADD_ITEM = "ADD_ITEM";
const ADD_ITEM_INTERNATIONAL = "ADD_ITEM_INTERNATIONAL";
const REMOVE_ITEM = "REMOVE_ITEM";
const SET_ITEM_QUANTITY = "SET_ITEM_QUANTITY";
const SET_ITEM_DISCOUNT = "SET_ITEM_DISCOUNT";
const SET_CLIENT = "SET_CLIENT";
const SET_PROVIDER = "SET_PROVIDER";
const SET_EMPLACEMENT = "SET_EMPLACEMENT";
const SET_REGLEMENTS = "SET_REGLEMENTS";
const SET_ACHAT = "SET_ACHAT";
const SET_FODEC = "SET_FODEC";
const SET_ITEMS_STATE = "SET_ITEMS_STATE";
const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
const SET_ITEM_MARGE = "SET_ITEM_MARGE";
const SET_DETAILS = "SET_DETAILS";
const QUOTES_ACTIONS = {
  SET_QUOTE_TO_UPDATE,
};

const ITEMS_ACTIONS = {
  CLEAR_ITEMS,
  SET_ITEM_MARGE,
  SET_TOTAL,
  SET_TOTAL_DISCOUNT,
  SET_UNIT_PRICE_TTC,
  SET_UNIT_PRICE_HT,
  ADD_ITEM,
  ADD_ITEM_INTERNATIONAL,
  REMOVE_ITEM,
  SET_ITEM_QUANTITY,
  SET_ITEM_DISCOUNT,
  SET_CLIENT,
  SET_PROVIDER,
  SET_EMPLACEMENT,
  SET_REGLEMENTS,
  SET_ACHAT,
  SET_FODEC,
  SET_ITEMS_STATE,
  SET_SELECTED_ITEM,
  SET_DETAILS,
  SET_TITLE,
};

export {
  AUTH_ACTIONS,
  MODE_ACTIONS,
  UI_ACTIONS,
  QUOTES_ACTIONS,
  ITEMS_ACTIONS,
};
