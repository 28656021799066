import CAxios from "../../Utils/CAxios";
import printJS from "print-js";
import { DataExistsException } from "../../DataExitsException";
import { errorMsg, successMsg } from "../../Utils/Utils";
const ls = require("localstorage-ttl");

export const toCashReglement = async (id) => {
  const { data } = await CAxios.post(`/api/cache/reglement`, {
    id: id,
  });

  return data.success && data.success ? data.success : [];
};

export const toGetQuotes = async (page, perPage, options) => {
  try {
    const { data } = await CAxios.post(`/api/quotes/list`, {
      perPage: perPage,
      page: page,
      options: options,
    });
    return data.success && data.success.quotes ? data.success.quotes : [];
  } catch (e) {
    return null;
  }
};

export const toUpdateDate = async (date, id) => {
  try {
    const { data } = await CAxios.post(`/api/quote/date`, {
      date: date,
      id: id,
    });
    return data.success && data.success.quotes ? data.success.quotes : [];
  } catch (e) {
    return null;
  }
};
export const toGetQuote = async (id) => {
  const { data } = await CAxios.get(`/api/quotes/${id}`);
  return data.success && data.success.quote
    ? { quote: JSON.parse(data.success.quote), stock: data.success.stock }
    : null;
};

export const toPrintBLs = async (ids) => {
  try {
    const { data } = await CAxios.get(`/api/bls/print/${"[" + ids + "]"}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintReglements = async (ref, type, clientId) => {
  var inputs = [];
  inputs.push(ref + "-1");
  inputs.push(type);
  inputs.push(clientId);
  try {
    const { data } = await CAxios.get(
      `/api/reglements/print/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000,
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintQuoteEmployee = async (id) => {
  try {
    const { data } = await CAxios.get(`/api/quote_employee/print/${id}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const sendEmailBL = async (id, type, to, msg) => {
  return CAxios.post("/api/email/bl", { id, type, to, msg });
};

export const sendEmailQuote = async (id, type, to, msg) => {
  return CAxios.post("/api/email/quote", { id, type, to, msg });
};

export const sendEmailFacture = async (id, type, to, msg) => {
  return CAxios.post("/api/email/facture", { id, type, to, msg });
};

export const sendEmailPo = async (id, type, to, msg) => {
  return CAxios.post("/api/email/po", { id, type, to, msg });
};

export const toPrintQuote = async (id, titre1, entete) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  inputs.push(entete);
  try {
    const { data } = await CAxios.get(
      `/api/quotes/print/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000,
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintQuoteNew = async (id, titre1, entete) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  inputs.push(entete);
  try {
    const { data } = await CAxios.get(
      `/api/quotes/print/new/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000,
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toCreateQuote = (quoteClient, quoteItems, note, quoteDiscount) => {
  const quote = {
    client: { id: quoteClient.id },
    d__discount: quoteDiscount,
    s__note: note,
  };
  const quote_items = quoteItems.map((item) => {
    return {
      d__discount: item.discount,
      d__quantity: item.quantity,
      d__unitPriceHT: item.uPriceHt,
      d__unitPriceTTC: item.uPriceTTC,
      article: { id: item.id },
      s__articleTitle: item.titre,
    };
  });
  return CAxios.post("/api/quotes/create", { quote, quote_items });
};

export const toEditQuote = (
  quoteId,
  quoteClient,
  quoteItems,
  note,
  quoteDiscount
) => {
  const quote = {
    id: quoteId,
    client: { id: quoteClient.id },
    s__note: note,
    d__discount: quoteDiscount,
  };
  const quote_items = quoteItems.map((item) => {
    return {
      d__discount: item.discount,
      d__quantity: item.quantity,
      d__unitPriceHT: item.uPriceHt,
      d__unitPriceTTC: item.uPriceTTC,
      article: { id: item.article.id },
      s__articleTitle: item.titre,
    };
  });
  return CAxios.post("/api/quotes/edit", { quote, quote_items });
};

export const toUpdateQuoteData = async (l__data) => {
  const { data } = await CAxios.post(
    "/api/quotes/update",
    JSON.stringify(l__data)
  );
  return data.success && data.success.quote ? data.success.quote : null;
};

export const toPrintBL = async (id, titre1, entete) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  inputs.push(entete);

  try {
    const { data } = await CAxios.get(`/api/bl/print/${"[" + inputs + "]"}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};
export const toPrintBLNew = async (id, titre1, entete) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  inputs.push(entete);

  try {
    const { data } = await CAxios.get(
      `/api/bl/print/new/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000,
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toCreateBlFromQuote = async (id, validate) => {
  try {
    const { data } = await CAxios.post(`/api/bl/from-quote`, { id, validate });
    return JSON.parse(data.success.bonlivraison);
  } catch (e) {
    return null;
  }
};

export const toPrintBlEmployee = async (id) => {
  try {
    const { data } = await CAxios.get(`/api/bl/print_employee/${id}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintFacture = async (id, titre1, entete, detail) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  inputs.push(entete);
  inputs.push(detail);
  try {
    const { data } = await CAxios.get(
      `/api/factures/print/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000,
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintFactureNew = async (id, grouped) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(grouped);
  try {
    const { data } = await CAxios.get(
      `/api/factures/newprint/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000,
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintRetenu = async (id) => {
  try {
    const { data } = await CAxios.get(`/api/retenu/${id}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintRetenuAchat = async (id) => {
  try {
    const { data } = await CAxios.get(`/api/retenu_achat/${id}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toGetBonsLivraision = async (page, perPage, options, active) => {
  try {
    const { data } = await CAxios.post(`/api/bl/list`, {
      perPage: perPage,
      page: page,
      options: options,
      active: active,
    });
    return data.success && data.success.bonslivraison
      ? data.success.bonslivraison
      : [];
  } catch (e) {
    return null;
  }
};

export const toRollBackBL = async (ids) => {
  try {
    const { data } = await CAxios.post(`/api/bl/rollback`, {
      ids: ids,
    });
    return data.success && data.success.bonslivraison
      ? data.success.bonslivraison
      : [];
  } catch (e) {
    return null;
  }
};
export const printAllBls = async (options) => {
  try {
    const { data } = await CAxios.post(
      `/api/all_bl/print`,
      { options: options },
      {
        responseType: "blob",
        timeout: 300000,
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};
export const toGetTotal = async (options, active) => {
  try {
    const { data } = await CAxios.post(`/api/bl/detail`, {
      options: options,
      active: active,
    });
    return data.success && data.success.bonslivraison
      ? data.success.bonslivraison
      : [];
  } catch (e) {
    return null;
  }
};

export const toGetTotalFactureVente = async (options) => {
  try {
    const { data } = await CAxios.post(`/api/factures/total`, {
      options: options,
    });
    return data.success && data.success.factures ? data.success.factures : [];
  } catch (e) {
    return null;
  }
};

export const toGetTotalQuotes = async (options) => {
  try {
    const { data } = await CAxios.post(`/api/quote/total`, {
      options: options,
    });
    return data.success && data.success.quotes ? data.success.quotes : [];
  } catch (e) {
    return null;
  }
};
export const fetchBLSByRef = async (page, perPage, ref) => {
  try {
    const { data } = await CAxios.post(`/api/bl/ref`, {
      perPage: perPage,
      page: page,
      ref: ref,
    });
    return data.success && data.success.bonslivraison
      ? data.success.bonslivraison
      : [];
  } catch (e) {
    return null;
  }
};

export const toGetBL = async (id) => {
  try {
    const { data } = await CAxios.get(`/api/bl/${id}`);

    return data.success && data.success.bonlivraison ? data.success : [];
  } catch (e) {
    return null;
  }
};

export const toCreateBL = (
  date,
  client,
  items,
  note,
  remise,
  reste,
  discount,
  total,
  reglement,
  validate
) => {
  let tva = 0;
  const bonlivraison_items = items.map((item) => {
    tva +=
      item.total +
      item.uPriceTTC * item.quantity * (item.discount / 100) -
      item.uPriceHt * item.quantity;
    return {
      d__discount: item.discount,
      d__quantity: item.quantity,
      d__unitPriceHT: item.uPriceHt,
      d__unitPriceTTC: item.uPriceTTC,
      d__tva:
        item.total +
        item.uPriceTTC * item.quantity * (item.discount / 100) -
        item.uPriceHt * item.quantity,
      d__total: item.total,
      article: { id: item.id },
      s__articleTitle: item.titre,
    };
  });
  const bonlivraison = {
    client: { id: client.id },
    d__discount: discount,
    s__note: note,

    d__total: total,
    d__tva: tva,
    reglement: reglement,
  };

  return CAxios.post("/api/bl/create", {
    bonlivraison,
    bonlivraison_items,
    remise: remise,
    reste,
    date: date,
    validate,
  });
};

export const toUpdateBl = (
  date,
  id,
  client,
  items,
  note,
  remise,
  reste,
  discount,
  total,
  reglement,
  validate
) => {
  let tva = 0;
  const bonlivraison_items = items.map((item) => {
    tva +=
      item.total +
      item.uPriceTTC * item.quantity * (item.discount / 100) -
      item.uPriceHt * item.quantity;
    return {
      d__discount: item.discount,
      d__quantity: item.quantity,
      d__unitPriceHT: item.uPriceHt,
      d__unitPriceTTC: item.uPriceTTC,
      d__tva:
        item.total +
        item.uPriceTTC * item.quantity * (item.discount / 100) -
        item.uPriceHt * item.quantity,
      d__total: item.total,
      article: { id: item.article.id },
      s__articleTitle: item.titre,
    };
  });
  const bonlivraison = {
    id: id,
    client: { id: client.id },
    reste,
    d__discount: discount,
    s__note: note,
    d__total: total,
    d__tva: tva,
    reglement: reglement,
  };
  return CAxios.post("/api/bl/update", {
    bonlivraison,
    bonlivraison_items,
    remise: remise,
    date: date,
    validate: validate,
  });
};

export const toDeleteBL = async (ids) => {
  return await CAxios.post("/api/bl/delete", { ids: ids });
};

export const toDeleteQuotes = async (ids) => {
  return await CAxios.post("/api/quotes/delete", { ids: ids });
};
export const toUpdateBlData = async (l__data) => {
  const { data } = await CAxios.post(
    "/api/clientMf/update",
    JSON.stringify(l__data)
  );
  return data.success && data.success.bl ? data.success.bl : null;
};

export const toUpdateFactureMf = async (l__data) => {
  const { data } = await CAxios.post(
    "/api/factureMF/update",
    JSON.stringify(l__data)
  );
  return data.success && data.success.facture ? data.success.facture : null;
};
/*
export const toUpdateBlData = async (
  id,
  client,
  items,
  note,
  discount,
  total,
  reglement
) => {
  let tva = 0;
  const bonlivraison_items = items.map((item) => {
    tva +=
      item.total +
      item.uPriceTTC * item.quantity * (item.discount / 100) -
      item.uPriceHt * item.quantity;
    return {
      d__discount: item.discount,
      d__quantity: item.quantity,
      d__unitPriceHT: item.uPriceHt,
      d__unitPriceTTC: item.uPriceTTC,
      d__tva:
        item.total +
        item.uPriceTTC * item.quantity * (item.discount / 100) -
        item.uPriceHt * item.quantity,
      d__total: item.total,
      article: { id: item.id },
    };
  });
  const bonlivraison = {
    id: id,
    client: { id: client.id },
    d__discount: discount,
    s__note: note,
    d__total: total,
    d__tva: tva,
    reglement: reglement,
  };

  return CAxios.post("/api/bl/update", { bonlivraison, bonlivraison_items });
};*/

export const toCreateFacture = (bls, data) => {
  var ids = [];
  bls.forEach((bl) => {
    ids.push(bl.id);
  });
  return CAxios.post("/api/factures/create", {
    bls: ids,
    data: data,
  });
};

export const toGetFactures = async (page, perPage, options) => {
  try {
    const { data } = await CAxios.post(`/api/factures/list`, {
      perPage: perPage,
      page: page,
      options: options,
    });
    return data.success && data.success.factures ? data.success.factures : [];
  } catch (e) {
    return null;
  }
};

export const toGetFacturesAll = async (page, perPage, options) => {
  try {
    const { data } = await CAxios.post(`/api/factures/all`, {
      perPage: perPage,
      page: page,
      options: options,
    });
    return data.success && data.success.factures ? data.success.factures : [];
  } catch (e) {
    return null;
  }
};

export const payFacture = async (id, reglements, reste) => {
  const { data } = await CAxios.post("/api/facture/pay", {
    id: id,
    reglements: reglements,
    reste,
  });
  return data.success && data.success.pay ? data.success.pay : null;
};

export const payFactureUpdate = async (id, reglements) => {
  const { data } = await CAxios.post("/api/facture/pay/update", {
    id: id,
    reglements: reglements,
  });
  return data.success && data.success.pay ? data.success.pay : null;
};

export const payBl = async (id, remise, reste, reglements, validate) => {
  const { data } = await CAxios.post("/api/bl/pay", {
    id: id,
    reglements: reglements,
    remise: remise,
    reste,
    validate,
  });
  return data.success && data.success.pay ? data.success.pay : null;
};

export const updatePayBl = async (id, remise, reglements, validate) => {
  const { data } = await CAxios.post("/api/bl/pay/update", {
    id: id,
    reglements: reglements,
    remise: remise,
    validate,
  });
  return data.success && data.success.pay ? data.success.pay : null;
};

export const toUpdateFactureData = async (l__data) => {
  const { data } = await CAxios.post(
    "/api/facture/update",
    JSON.stringify(l__data)
  );
  return data.success && data.success.facture ? data.success.facture : null;
};

export const toGetFacture = async (id) => {
  try {
    const { data } = await CAxios.get("/api/factures/" + id);
    return JSON.parse(data.success.facture);
  } catch (e) {
    return null;
  }
};

export const toGetFactureWithReglements = async (id) => {
  try {
    const { data } = await CAxios.get("/api/factures/detail/" + id);
    return data.success.facture;
  } catch (e) {
    return null;
  }
};

export const toGetClientsList = async () => {
  try {
    const { data } = await CAxios.get(`/api/clients/list`);
    ls.set(`clients`, JSON.parse(data.success.clients));
    return JSON.parse(data.success.clients);
  } catch (e) {
    return null;
  }
};

export const toGetClientsListIds = async () => {
  try {
    const { data } = await CAxios.get(`/api/clients/ids`);
    return data.success.clients;
  } catch (e) {
    return null;
  }
};

export const toAddClient = async (client) => {
  const response = await CAxios.post(
    `/api/clients/add`,
    JSON.stringify(client)
  );
  if (response.status === 303) {
    throw new DataExistsException();
  }
  const { data } = response;
  return data.success && data.success.client
    ? JSON.parse(data.success.client)
    : null;
};

export const toEditClient = async (client) => {
  const { data } = await CAxios.post(`/api/clients/edit`, client);
  return data.success && data.success.client
    ? JSON.parse(data.success.client)
    : null;
};

export const toDeleteClient = async (clientId) => {
  await CAxios.post(`/api/clients/delete/${clientId}`);
};

export const deleteFacture = async (id) => {
  await CAxios.post(`/api/factures/delete`, { id: id });
};

export const toUnlockFacture = async (id) => {
  await CAxios.post(`/api/factures_unlock/unlock`, { id: id });
};
export const toUnlockBL = async (id, userId) => {
  await CAxios.post(`/api/bl/unlock`, { id: id, userId });
};
export const toGetReglementsClient = async (page, perPage, id) => {
  const { data } = await CAxios.post(`/api/reglements/list`, {
    page: page,
    perPage: perPage,
    id: id,
  });
  return data.success && data.success.reglements
    ? data.success.reglements
    : null;
};

export const toFilterReglement = async (
  ref,
  page,
  perPage,
  type,
  clientId,
  userId,
  date,
  factureBL
) => {
  const { data } = await CAxios.post(`/api/reglements/filter`, {
    ref,
    page: page,
    perPage: perPage,
    type: type,
    clientId: clientId,
    userId: userId,
    date,
    factureBL,
  });
  return data.success && data.success.bls ? data.success.bls : null;
};

export const toPrintFilterReglement = async (
  page,
  perPage,
  type,
  clientId,
  userId,
  date,
  factureBL
) => {
  const { data } = await CAxios.post(`/api/reglements/filter/print`, {
    page: page,
    perPage: perPage,
    type: type,
    clientId: clientId,
    userId: userId,
    date,
    factureBL,
  });
  return data.success && data.success.bls ? data.success.bls : null;
};

export const toDeleteReglementClient = async (id) => {
  await CAxios.post(`/api/reglementClient/delete/${id}`);
};

export const getBlsOfClients = async (id) => {
  const { data } = await CAxios.post(`/api/client/bls/${id}`);
  return data.success && data.success.bls ? data.success.bls : null;
};

export const toGetClientHistory = async (id, start, end) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(Date.parse(start) / 1000);
  inputs.push(Date.parse(end) / 1000);

  const { data } = await CAxios.post(`/api/client/data/${"[" + inputs + "]"}`);
  return data.success && data.success.data ? data.success.data : [];
};

export const toPrintClientHistory = async (id, start, end) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(Date.parse(start) / 1000);
  inputs.push(Date.parse(end) / 1000);
  try {
    const { data } = await CAxios.get(
      `/api/client/data/print/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000,
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const onDeactivatePatient = async (id) => {
  const { data } = await CAxios.post(`/api/client/change/${id}`);
  return data.success && data.success ? data.success : null;
};

export const getQuotesOfClients = async (id) => {
  const { data } = await CAxios.post(`/api/client/quotes/${id}`);
  return data.success && data.success.quotes ? data.success.quotes : null;
};

export const getFactureOfClients = async (id) => {
  const { data } = await CAxios.post(`/api/client/factures/${id}`);
  return data.success && data.success.factures
    ? JSON.parse(data.success.factures)
    : [];
};

export const getReglementsOfClients = async (id) => {
  const { data } = await CAxios.post(`/api/client/reglements/${id}`);
  return data.success && data.success.reglements
    ? JSON.parse(data.success.reglements)
    : [];
};

export const getIds = async () => {
  const { data } = await CAxios.post(`/api/facture/ids`);
  return data.success && data.success.ids ? data.success.ids : null;
};

export const getUnpaiedClient = async (id) => {
  const { data } = await CAxios.post(`/api/bl/client`, { id: id });
  return data.success && data.success.bonslivraison
    ? data.success.bonslivraison
    : [];
};

export const toSendNotification = async (text, refs) => {
  const { data } = await CAxios.post(`/api/push/send`, {
    text: text,
    refs: refs,
  });
  return data.success && data.success.notif ? data.success.notif : null;
};

export const toGetUnPaiedBL = async () => {
  const { data } = await CAxios.post(`/api/bl/unpaied`, {});
  return data.success && data.success.bls ? data.success.bls : null;
};

export const toAddNote = async (id, note) => {
  const { data } = await CAxios.post(`/api/client/note/add`, {
    id: id,
    note: note,
  });
  if (data.success) {
    successMsg("Note enregistrée avec succées");
  }
  return data.success && data.success ? data.success : [];
};

export const toRemoveNote = async (id) => {
  const { data } = await CAxios.post(`/api/client/note/remove`, {
    id: id,
  });
  if (data.success) {
    successMsg("Note supprimée avec succées");
  }
  return data.success && data.success ? data.success : [];
};

export const toValidateReglement = async (value, id) => {
  const { data } = await CAxios.post(`/api/reglement/validate`, {
    id: id,
    value,
  });
  if (data.success && data.success.caisseItem) {
    successMsg("Réglement validée avec succées");
    return true;
  } else {
    errorMsg("Réglement n'est pas trouvée ou a été déja validé");
    return false;
  }
};
export const toGetStatClient = async () => {
  const { data } = await CAxios.post(`/api/client/stat`, {});
  return data.success ? data.success : null;
};

export const toGetStatSales = async () => {
  const { data } = await CAxios.post(`/api/sales/stat`, {});
  return data.success ? data.success : null;
};

export const getEmmerre = async (ref) => {
  const { data } = await CAxios.post(`/api/emmerre/get`, { ref });
  return data.success ? data.success : null;
};
